import { render, staticRenderFns } from "./buySignup.vue?vue&type=template&id=44c29cc8&scoped=true&"
import script from "./buySignup.vue?vue&type=script&lang=js&"
export * from "./buySignup.vue?vue&type=script&lang=js&"
import style0 from "./buySignup.vue?vue&type=style&index=0&id=44c29cc8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c29cc8",
  null
  
)

export default component.exports