<template>
  <div class="buySignup">
    <div class="main-card">
      <div class="cells">
        <el-steps align-center :active="seep" finish-status="success">
          <el-step title="验证手机号"></el-step>
          <el-step title="创建密码"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
      </div>

      <div class="cell" v-if="seep == 0">
        <div class="title">
          <i class="el-icon-back icon" @click="fanhui"></i>
          请输入验证码
        </div>
        <div style="font-size: 12px; margin-bottom: 10px">
          您的验证码已发送至
        </div>
        <div style="font-size: 14px">(+86){{ phone }}</div>
        <div>
          <vue-sms-check-code
            title=" "
            :code-num="6"
            :is-error="isError"
            error-color="#ee4d2d"
            @finish="getSmsCodes"
            style="width: 300px; font-size: 16px"
          />
        </div>
        <div style="font-size: 14px; color: green">
          验证码：{{ smsCodeText }}
        </div>
        <div style="color: #b0b0b0; font-size: 14px; margin: 30px 0">
          <span v-if="num != 60"> 请稍等{{ num }}秒后重新发送</span>
          <span
            v-else
            style="color: #ee4d2d; text-decoration: underline"
            @click="getSmsCodeF"
            >重新发送</span
          >
        </div>
        <div>
          <el-button
            @click="seep = 1"
            type="primary"
            style="min-width: 320px; margin-top: 20px"
            :disabled="smsCode == ''"
            >验证</el-button
          >
        </div>
      </div>
      <div class="cell" v-if="seep == 1">
        <div class="title">
          <!-- <i class="el-icon-back icon" @click="fanhui"></i> -->
          设置登入密码
        </div>
        <div style="font-size: 14px; margin: 60px 0 40px; color: #b0b0b0">
          密码长度该为8-16个字符，必须包含大小写字母/数字/符号任意两者组合。
        </div>
        <div style="margin-bottom: 20px">
          <el-input
            style="width: 300px"
            v-model.trim="pwd"
            show-password
            placeholder="输入密码"
          ></el-input>
        </div>
        <div>
          <el-input
            style="width: 300px"
            v-model.trim="confirm_pwd"
            show-password
            placeholder="再次输入密码"
          ></el-input>
        </div>
        <div>
          <el-button
            @click="submit"
            type="primary"
            style="min-width: 320px; margin-top: 40px"
            :disabled="smsCode == ''"
            >储存</el-button
          >
        </div>
      </div>
      <div v-if="seep == 2" class="cella">
        <img :src="successImg" alt="" />
        <div class="djs">{{ djs }}秒后自动返回</div>
      </div>
    </div>
  </div>
</template>
<script>
import { buyRegister } from "@/api/buy.js";
import { getSmsCode } from "@/api/common.js";
export default {
  data() {
    return {
      successImg: require("@/assets/img/buy/buy-success.png"),
      isError: false,
      phone: sessionStorage.SIGNUPPHONE,
      num: 59,
      time: null,
      time2: null,
      smsCodeText: "获取中···",
      smsCode: "",
      seep: 0,
      djs: 5,
      pwd: "",
      confirm_pwd: "",
    };
  },
  created() {
    this.getSmsCodeF();
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
  methods: {
    getSmsCodeF() {
      let _this = this;
      this.smsCodeText = "获取中···";
      getSmsCode({
        mobile: this.phone,
      }).then((res) => {
        if (res.code == 1) {
          this.time = setInterval(function () {
            _this.num--;
            _this.smsCodeText = res.data.code;
            if (_this.num == 0) {
              clearInterval(_this.time);
              _this.num = 60;
            }
          }, 1000);
        }
      });
    },
    getSmsCodes(value) {
      let _this = this;
      this.isError = false;
     
      if (value !== this.smsCodeText) {
        setTimeout(() => {
          _this.isError = true;
          _this.$message.error("验证码错误");
        }, 500);
      } else {
        this.smsCode = this.smsCodeText;
      }
    },
    fanhui() {
      this.$router.go(-1);
    },
    submit() {
      if (this.pwd != this.confirm_pwd) {
        this.$message.error("两次输入密码不一致");
        return false;
      }
      let _this = this;
      buyRegister({
        mobile: this.phone,
        code: this.smsCode,
        pwd: this.pwd,
        confirm_pwd: this.confirm_pwd,
      }).then((res) => {
        if (res.code == 1) {
          this.seep = 2;
          _this.time2 = setInterval(function () {
            _this.djs--;
            if (_this.djs == 0) {
              clearInterval(_this.time2);
              _this.$store.state.buyLoginType = 2;
              _this.$router.go(-1);
            }
          }, 1000);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.buySignup {
  .cells {
    width: 600px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin: 40px auto 0;
  }
  .cell {
    width: 460px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin: 40px auto 0;
    box-shadow: 0 1px 8px rgba(220, 220, 220, 0.8);
    text-align: center;
    padding-bottom: 50px;
    .title {
      position: relative;
      font-size: 18px;
      margin-bottom: 30px;
      .icon {
        position: absolute;
        left: 0;
        top: 0;
        color: #ee4d2d;
        font-size: 24px;
      }
    }
  }
  .cella {
    text-align: center;
    padding-top: 60px;
    .djs {
      color: #b0b0b0;
      margin-top: 10px;
    }
  }
}
</style>